<template>
  <List />
</template>

<script>
  import List from '../pages/groups/GroupsList';

  export default {
    components: {
      List
    },
    name: 'GroupList'
  };
</script>